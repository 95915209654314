import {
  Autocomplete,
  Box,
  Button,
  Chip,
  ClickAwayListener,
  Stack,
  TextField,
  Tooltip,
  Typography,
  styled
} from "@mui/material";
import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import TuneIcon from "@mui/icons-material/Tune";
import { gql } from "@apollo/client";
import { useSafeQuery } from "~/core/utils/apollo";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { useHistory } from "react-router";
import { convertQueryToString } from "~/solo/pages/accounts/SearchPage";
import { useDispatch, useSelector } from "react-redux";
import { cleanCreditorName, saveCreditorName, saveAllCreditors } from "~/solo/features/creditor/creditor";
import type { RootState } from "~/solo/store";

const SentenceInput = styled("input")({
  margin: 0,
  border: "none",
  position: "absolute",
  top: -9,
  left: 0,
  width: "100%",
  paddingTop: "10px",
  paddingBottom: "10px",
  paddingLeft: "10px"
});

const Label = styled("label")({
  display: "inline-block",
  position: "relative",
  minWidth: "2em",
  minHeight: "1.4em",
  padding: "0px 5px"
});

const Template = styled("span")({
  whiteSpace: "pre"
});
export const CreditorsQuery = gql(`
query CreditorsQuery {
  creditors: Creditors {
    id
    company_name
  }
}
`);
const InputSearch = styled(TextField)`
  .MuiInput-input {
    min-width: 200px;
  }
`;
interface ISearchBar {
  isOpenAdvanceSearch: boolean;
  setOpenAdvanceSearch: (isOpen: boolean) => void;
  handleSearch: (isClickedSearch: boolean) => void;
  isOnlySearch?: boolean;
  searchTerms: string[];
  setSearchTerms: (item: string[]) => void;
  inputSearchBarSize?: string;
  inputsAdvancedSearch?: string;
  executeSearch: (item: string[]) => void;
  sendCreditorSelected: (value: string | undefined) => void;
  searchTermDeleted: (value: boolean) => void;
  shouldMoveResults: boolean;
  setMoveResults: (value: boolean) => void;
}

function SearchBar({
  isOpenAdvanceSearch,
  setOpenAdvanceSearch,
  handleSearch,
  isOnlySearch,
  searchTerms,
  setSearchTerms,
  inputSearchBarSize,
  inputsAdvancedSearch,
  executeSearch,
  sendCreditorSelected,
  searchTermDeleted,
  shouldMoveResults,
  setMoveResults
}: ISearchBar): React.ReactElement {
  const { data } = useSafeQuery(CreditorsQuery);
  const history = useHistory();
  const theme = useTheme();
  const dispatch = useDispatch();

  const creditorIdSelected = useSelector((state: RootState) => state.creditor.value);

  const creditors = data?.creditors?.map(item => {
    return {
      label: item?.company_name,
      value: item?.id
    };
  });

  React.useEffect(() => {
    dispatch(saveAllCreditors(creditors));
  }, []);

  creditors?.sort((a, b) => {
    const companyNameA = a?.label?.toLowerCase();
    const companyNameB = b?.label?.toLowerCase();
    if (companyNameA && companyNameB) {
      if (companyNameA < companyNameB) {
        return -1;
      }
      if (companyNameA > companyNameB) {
        return 1;
      }
    }
    return 0;
  });
  const [inputHasTheWords, setInputHasTheWords] = React.useState("");
  const [inputValue, setInputValue] = React.useState("");

  const [phoneNumber, setPhoneNumber] = React.useState<string>("");
  const [email, setEmail] = React.useState("");
  const [accountNumber, setAccountNumber] = React.useState("");
  const [referenceNumber, setReferenceNumber] = React.useState("");
  const [creditor, setCreditor] = React.useState<string | null>("");
  const [creditorId, setCreditorId] = React.useState<string | undefined>("");

  const [isEdit, setEdit] = React.useState(false);
  const [indexToEdit, setIndexToEdit] = React.useState<number>();
  const [valueEdit, setValueEdit] = React.useState<string | undefined>("");

  const commaKey = 188;
  const semiColonKey = 186;

  function convertToChip(event?: React.KeyboardEvent): void {
    if (inputValue[0] === "," || inputValue[0] === ";") return;
    if (inputValue.length) {
      if (!isEdit) {
        const newSelectedItem = [...searchTerms];
        const duplicatedValues = newSelectedItem.indexOf(inputValue.trim());

        if (duplicatedValues !== -1) {
          setInputValue("");
          setInputHasTheWords("");
          return;
        }

        if (
          inputValue !== "Email: " &&
          inputValue !== "Phone: " &&
          inputValue !== "Creditor: " &&
          inputValue !== "TA #: " &&
          inputValue !== "Reference: "
        ) {
          newSelectedItem.push(`${inputValue}`);
          setSearchTerms(newSelectedItem);
        }
        if (
          event?.key === "Tab" ||
          event?.key === "Enter" ||
          event?.code === "Comma" ||
          event?.code === "Semicolon"
        ) {
          handleSearch?.(true);
          executeSearch(newSelectedItem);
          setMoveResults(true);
        }

        setInputValue("");
        setInputHasTheWords("");

        const shouldNotDeleteChips = false;
        clearAll(shouldNotDeleteChips);
      }
    }
  }

  function handleKeyDown(event: React.KeyboardEvent): void {
    if (
      (event.key === "Enter" ||
        event.key === "Tab" ||
        event.code === "Comma" ||
        event.code === "Semicolon") &&
      inputValue.length
    ) {
      if (phoneNumber.length > 0 && phoneNumber.length < 14) {
        setInputValue("");
      }
      if (!isValidEmail) {
        setInputValue("");
        return;
      } else {
        convertToChip(event);
      }
    }
    if (searchTerms?.length && !inputValue.length && event.key === "Backspace" && !isEdit) {
      setSearchTerms(searchTerms.slice(0, searchTerms.length - 1));
    }
  }

  const handleDelete = (item: string) => () => {
    if (searchTerms.some(v => v.includes("Creditor:"))) {
      dispatch(cleanCreditorName());
    }
    let items = [...searchTerms];
    items.splice(items.indexOf(item), 1);
    setInputHasTheWords("");
    searchTermDeleted(true);
    setSearchTerms(items);

    const url = window.location.href;

    //this is for when you aren't in the search results page and you are in
    //account information, you will be able to remove a chip without be redirect like
    //below changing the url of the searchQuery
    if (url.search("advance") === -1) {
      setFocus();
      return;
    }

    const hasCreditor = items?.some(item => item.includes("Creditor:"));
    items = items?.filter(item => !item.includes("Creditor:"));
    const searchQuery = convertQueryToString(items);
    const creditorIdSelectedForUrl = creditorId ?? creditorIdSelected;
    if (!hasCreditor) {
      if (searchQuery === "") {
        history.push(`/app/accounts/search/advance`);
      } else {
        history.push(`/app/accounts/search/advance?searchQuery=${searchQuery}`);
      }
    } else if (searchQuery != "") {
      history.push(
        `/app/accounts/search/advance?searchQuery=${searchQuery}&creditorId=${creditorIdSelectedForUrl}`
      );
    } else if (searchQuery === "" && creditorIdSelectedForUrl !== "") {
      history.push(`/app/accounts/search/advance?creditorId=${creditorIdSelectedForUrl}`);
    }
    setFocus();
  };

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>): void {
    if (event.target.value === "," || event.target.value === ";") return;
    setInputValue(event.target.value);
  }

  const [labelNew, setNewLabel] = React.useState("");

  function handleOnChangeEdit(event: React.ChangeEvent<HTMLInputElement>): void {
    const newValue = event.target.value;
    if (newValue === "Phone: +1") return;
    if (labelNew.length === 0) {
      setValueEdit(newValue);
    }
    if (labelNew === newValue) return;
    if (newValue.includes(`${labelNew}`)) {
      setValueEdit(newValue);
    }
  }

  React.useEffect(() => {
    const valueEditable = searchTerms.filter((item, index) => indexToEdit === index);
    if (valueEditable !== undefined) {
      setValueEdit(valueEditable[0]);
    }
  }, [indexToEdit]);

  function createChipThatWasEdited(index: number): void {
    if (labelNew.trim() === "Email:") {
      const email = valueEdit?.split(":")[1]?.trim();
      const shouldUpdateState = false;
      if (email !== undefined && !checkValidEmail(email, shouldUpdateState)) return;
    }
    if (labelNew.trim() === "Phone:" && valueEdit?.length !== 20) return;
    if (valueEdit?.trim() === labelNew.trim()) return;
    const newSelectedItem = [...searchTerms];
    newSelectedItem[index] = `${valueEdit}`;
    setSearchTerms(newSelectedItem);
    setEdit(false);
    setIndexToEdit(-1);
  }

  function getStartAdornment(): React.ReactElement {
    return (
      <>
        <SearchIcon sx={{ marginRight: 1, ml: 2 }} />
        {searchTerms?.map((item, index) => {
          return (
            <Box key={index}>
              <>
                {indexToEdit !== index && (
                  <Chip
                    key={item + String(index)}
                    tabIndex={-1}
                    label={`${item}`}
                    onDoubleClick={() => {
                      if (!item.includes("Creditor:")) {
                        setEdit(true);
                        setIndexToEdit(index);
                        if (!item.includes(":")) {
                          setNewLabel("");
                        } else {
                          const label = item.split(":")[0];
                          setNewLabel(`${label}:`);
                        }
                      }
                    }}
                    sx={{
                      margin: "-1px 2px",
                      backgroundColor: theme.palette.common.white,
                      border: `1px solid ${theme.palette.grey[200]}`
                    }}
                    onDelete={handleDelete(item)}
                  />
                )}
              </>
              <>
                {isEdit && indexToEdit === index && (
                  <ClickAwayListener onClickAway={() => createChipThatWasEdited(index)}>
                    <Label>
                      <Template>{valueEdit}</Template>
                      <SentenceInput
                        style={{ background: theme.palette.primary.light }}
                        value={valueEdit}
                        maxLength={labelNew.trim() === "Phone:" ? 20 : undefined}
                        onBlur={() => {
                          createChipThatWasEdited(index);
                        }}
                        onChange={event => {
                          handleOnChangeEdit(event);
                        }}
                        onKeyDown={event => {
                          if (event?.key === "Enter") {
                            createChipThatWasEdited(index);
                          }
                        }}
                      />
                    </Label>
                  </ClickAwayListener>
                )}
              </>
            </Box>
          );
        })}
      </>
    );
  }
  const htmlElRef = React.useRef<HTMLInputElement>(null);
  const setFocus = () => {
    htmlElRef?.current?.focus();
  };

  function getEndAdornment(): React.ReactElement {
    return (
      <Stack
        sx={{
          position: "relative",
          left: "-80px",
          backgroundColor: theme.palette.common.white,
          height: "45px",
          borderTop: `1px solid ${theme.palette.grey[200]}`
        }}
        pr={1}
        direction={"row"}>
        <>
          <Tooltip placement="right" arrow title={"Clear search"}>
            <CloseIcon
              sx={{
                cursor: "pointer",
                marginRight: 1,
                marginLeft: 2,
                color: theme.palette.common.black,
                position: "relative",
                top: "16px",
                left: "1px"
              }}
              onClick={e => {
                e.stopPropagation();
                setIsValidEmail(true);
                clearAll(true, true);
                setFocus();
              }}
            />
          </Tooltip>
        </>
        <TuneIcon
          sx={{ cursor: "pointer", color: theme.palette.common.black, position: "relative", top: "16px" }}
          onClick={e => {
            e.stopPropagation();
            setOpenAdvanceSearch(!isOpenAdvanceSearch);
            setMoveResults(!shouldMoveResults);
          }}
        />
      </Stack>
    );
  }

  function clearAll(shouldRemoveChips?: boolean, shouldEmptyCreditorField?: boolean): void {
    if (shouldRemoveChips) {
      setSearchTerms([]);
    }
    setInputValue("");
    setPhoneNumber("");
    setInputHasTheWords("");
    setEmail("");
    setAccountNumber("");
    setReferenceNumber("");
    if (shouldEmptyCreditorField) {
      setCreditor("");
    }
  }

  function formatPhoneNumber(value: string): string {
    if (!value) return value;

    const phoneNumber = value.replace(/[^\d]/g, "");

    const phoneNumberLength = phoneNumber.length;

    if (phoneNumberLength < 4) return phoneNumber;

    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }

    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  }

  const [isValidEmail, setIsValidEmail] = React.useState(true);

  function checkValidEmail(emailToValidate: string, shouldUpdateState: boolean): boolean {
    const regex = /^[0-9A-z]+(?:\.[0-9A-z]+)*@[A-z0-9]{2,}(?:\.[A-z]{2,}.+(?<!web)$(?<!\.)$)+$/gm;

    if (!regex.test(emailToValidate)) {
      if (shouldUpdateState) {
        setIsValidEmail(false);
      }
      return false;
    }
    if (shouldUpdateState) {
      setIsValidEmail(true);
    }
    return true;
  }

  React.useEffect(() => {
    const creditorSelected = creditors?.find(item => {
      if (item.label === creditor) {
        return item;
      }
      return;
    });
    setCreditorId(creditorSelected?.value);

    sendCreditorSelected(creditorSelected?.value);
  }, [creditor]);

  return (
    <>
      <Stack direction={"row"}>
        <Stack
          sx={{
            overflowX: "auto",
            backgroundColor: theme.palette.common.white,
            border: `1px solid ${theme.palette.grey[200]}`,
            overflowY: "hidden"
          }}>
          <InputSearch
            inputRef={htmlElRef}
            autoComplete="off"
            onBlur={() => {
              convertToChip();
            }}
            autoFocus
            onClick={e => {
              e.stopPropagation();
              setMoveResults(!shouldMoveResults);
            }}
            placeholder={searchTerms.length > 0 ? "" : "Type in a debtor name, email or debt ID"}
            onKeyDown={event => {
              if (event.key === "Enter") {
                handleKeyDown(event);
                handleSearch(true);
              }
            }}
            value={inputValue}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              if (isEdit) return;
              handleInputChange(event);
            }}
            sx={{
              backgroundColor: theme.palette.common.white,
              width: inputSearchBarSize,
              height: "49px",
              pt: 1.5,
              px: 1
            }}
            InputProps={{
              startAdornment: getStartAdornment(),
              disableUnderline: true
            }}
            fullWidth
            variant="standard"
          />
        </Stack>
        {getEndAdornment()}
      </Stack>
      {!shouldMoveResults && (
        <ClickAwayListener
          onClickAway={() => {
            setMoveResults(true);
          }}>
          <Stack
            width={inputSearchBarSize}
            height={"330px"}
            p={2.5}
            pt={0}
            position="absolute"
            mt={1}
            sx={{
              backgroundColor: theme.palette.common.white,
              boxShadow: "0px 2px 5px 0px rgba(0,0,0,0.75)"
            }}>
            <Stack pt={2.3} direction={"row"} justifyContent="space-between">
              <Stack>
                <TextField
                  autoComplete="off"
                  onBlur={() => {
                    if (email.length === 0) {
                      setIsValidEmail(true);
                    }
                    if (!isValidEmail) {
                      setInputValue("");
                      return;
                    } else {
                      setEmail("");
                      convertToChip();
                    }
                  }}
                  sx={{ width: inputsAdvancedSearch }}
                  variant="outlined"
                  label={!isValidEmail ? "Error" : "Enter Email Address"}
                  placeholder="Enter Email Address"
                  value={email}
                  error={!isValidEmail}
                  helperText={!isValidEmail ? "You must enter a valid email" : ""}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    if (isEdit) return;
                    if (event.target.value === "," || event.target.value === ";") return;
                    checkValidEmail(event.target.value, true);
                    setEmail(event.target.value);
                    setInputValue(`Email: ${event.target.value}`);
                  }}
                  onKeyDown={event => {
                    const emailValue = (event.target as HTMLInputElement).value;
                    checkValidEmail(emailValue, true);
                    handleKeyDown(event);
                  }}
                />
              </Stack>
              <Stack>
                <TextField
                  autoComplete="off"
                  sx={{ width: inputsAdvancedSearch }}
                  onBlur={() => convertToChip()}
                  variant="outlined"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    if (isEdit) return;
                    if (event.target.value === "," || event.target.value === ";") return;
                    setInputHasTheWords(event.target.value);
                    setInputValue(event.target.value);
                  }}
                  onKeyDown={event => handleKeyDown(event)}
                  label="Has the Words"
                  placeholder="Has the Words"
                  value={inputHasTheWords}
                />
              </Stack>
            </Stack>
            <Stack pt={2.3} direction={"row"} justifyContent="space-between">
              <Stack>
                <TextField
                  autoComplete="off"
                  onBlur={() => {
                    if (phoneNumber.length === 0) {
                      setPhoneNumber("");
                      setInputValue("");
                    }
                    if (phoneNumber.length > 0 && phoneNumber.length < 14) {
                      setInputValue("");
                    } else {
                      setPhoneNumber("");
                    }
                    if (phoneNumber.length > 0 && phoneNumber.length === 14) {
                      convertToChip();
                    }
                  }}
                  sx={{ width: inputsAdvancedSearch }}
                  variant="outlined"
                  label={phoneNumber.length > 0 && phoneNumber.length !== 14 ? "Error" : "Enter Phone Number"}
                  placeholder="Enter Phone Number"
                  error={phoneNumber.length > 0 && phoneNumber.length !== 14}
                  helperText={
                    phoneNumber.length > 0 && phoneNumber.length !== 14 ? "You must enter 10 numbers" : ""
                  }
                  value={phoneNumber}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    if (/[a-zA-Z]/.test(event.target.value)) return;
                    if (isEdit) return;
                    if (
                      event.target.value === "," ||
                      event.target.value === ";" ||
                      event.target.value === "+" ||
                      event.target.value === "+1"
                    )
                      return;

                    if (event.target.value.length === 19) return;
                    const formattedPhoneNumber = formatPhoneNumber(event.target.value);
                    setPhoneNumber(formattedPhoneNumber);
                    let numberWithOutFormat = event.target.value.replace(/[^\d]/g, "");
                    if (numberWithOutFormat.length === 11) {
                      numberWithOutFormat = numberWithOutFormat.slice(0, -1);
                    }
                    setInputValue(`Phone: +1 ${numberWithOutFormat}`);
                  }}
                  onKeyDown={event => {
                    const valueKeyDown = (event.target as HTMLInputElement).value;
                    if (/[a-zA-Z]/.test(valueKeyDown)) return;

                    if (
                      (event.key === "Tab" ||
                        event.key === "Enter" ||
                        event.which === commaKey ||
                        event.which === semiColonKey) &&
                      valueKeyDown.trim() === ""
                    ) {
                      return;
                    }

                    if (event.key === "Backspace") {
                      setPhoneNumber(valueKeyDown);
                    } else {
                      const formattedPhoneNumber = formatPhoneNumber(valueKeyDown);
                      if (formattedPhoneNumber.length === 17) {
                        return;
                      }
                      setPhoneNumber(`${formattedPhoneNumber}`);
                      if (phoneNumber.length > 0 && phoneNumber.length === 14) {
                        handleKeyDown(event);
                      }
                    }
                  }}
                />
              </Stack>
              <Stack>
                <TextField
                  autoComplete="off"
                  onBlur={() => {
                    convertToChip();
                    setAccountNumber("");
                  }}
                  sx={{ width: inputsAdvancedSearch }}
                  variant="outlined"
                  label="Enter TA Account Number"
                  placeholder="Enter TA Account Number"
                  value={accountNumber}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    if (isEdit) return;
                    if (event.target.value === "," || event.target.value === ";") return;
                    setAccountNumber(event.target.value);
                    setInputValue(`TA #: ${event.target.value}`);
                  }}
                  onKeyDown={event => handleKeyDown(event)}
                />
              </Stack>
            </Stack>
            <Stack pt={2.3} direction={"row"} justifyContent="space-between">
              <Stack>
                <Autocomplete
                  onBlur={() => {
                    convertToChip();
                  }}
                  onChange={event => {
                    const value = event.target as HTMLElement;
                    setCreditor(value.textContent);
                    dispatch(saveCreditorName(`Creditor: ${value.textContent}`));
                    setInputValue(`Creditor: ${value.textContent}`);
                  }}
                  disabled={searchTerms.some(v => v.includes("Creditor:")) || isEdit}
                  value={null}
                  disablePortal
                  options={creditors ?? []}
                  sx={{ width: inputsAdvancedSearch }}
                  renderInput={params => <TextField {...params} label="All Creditors" />}
                />
              </Stack>
              <Stack>
                <TextField
                  autoComplete="off"
                  onBlur={() => {
                    convertToChip();
                    setReferenceNumber("");
                  }}
                  sx={{ width: inputsAdvancedSearch }}
                  variant="outlined"
                  label="Enter Reference Number"
                  placeholder="Enter Reference Number"
                  value={referenceNumber}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    if (isEdit) return;
                    if (event.target.value === "," || event.target.value === ";") return;
                    setReferenceNumber(event.target.value);
                    setInputValue(`Reference: ${event.target.value}`);
                  }}
                  onKeyDown={event => handleKeyDown(event)}
                />
              </Stack>
            </Stack>
            <Stack
              direction={"row"}
              justifyContent="flex-end"
              mt={3}
              position={"absolute"}
              bottom={"10px"}
              right={"22px"}>
              <Button
                onClick={() => {
                  setIsValidEmail(true);
                  clearAll(true, true);
                }}
                size="small"
                sx={{ mr: 2.5 }}
                variant="text">
                CLEAR
              </Button>
              <Button
                size="small"
                variant="contained"
                disabled={(inputValue.length < 1 || isEdit) && searchTerms.length < 1}
                onClick={() => {
                  handleSearch(true);
                  executeSearch(searchTerms);
                  setMoveResults(true);
                }}>
                SEARCH
              </Button>
            </Stack>
          </Stack>
        </ClickAwayListener>
      )}
    </>
  );
}

export default React.memo(SearchBar);
