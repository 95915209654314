import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import * as React from "react";
import type { IAccountModel} from "~/gql/types";
import { IDataSource_Type, IPostalAddress_Type } from "~/gql/types";
import { DataRow } from "../../widgets/DataRow";
import { Address } from "../widgets/Address";

export interface ICustomerContactProps {
  account: IAccountModel;
}

export function CustomerContact({ account }: ICustomerContactProps): React.ReactElement {
  return (
    <>
      <Stack mb="1.25em">
        <Typography variant="body2">Email</Typography>
        <DataRow
          inline
          title={account.debt?.person?.emails[0]?.email ?? ""}
          titleTypography="body1"
          value={
            <FormControlLabel
              control={<Switch color="default" size="small" disabled />}
              label="Unsubscribed"
            />
          }
        />
      </Stack>
      <Stack mb="1.25em">
        <Typography variant="body2">Phone</Typography>
        <DataRow
          inline
          title={account.debt?.person?.phones[0]?.phone_number ?? ""}
          titleTypography="body1"
          value={
            <FormControlLabel
              sx={{ marginRight: "2.3em" }}
              control={<Switch color="default" defaultChecked disabled size="small" />}
              label="Subscribed"
            />
          }
        />
      </Stack>
      <Stack>
        <DataRow
          inline
          title="Address"
          titleTypography="body2"
          value={
            <FormControlLabel
              sx={{ marginRight: "2.3em", position: "relative", top: "28px" }}
              control={<Switch color="default" defaultChecked size="small" disabled />}
              label="Subscribed"
            />
          }
        />
      </Stack>

      <Address variant="body1" address={account.debt?.person?.addresses[0] ?? undefined} />
    </>
  );
}
