import { chain, sum } from "lodash";
import { formatDate, formatTimestamp, isValidDate } from "~/core/utils/formats";
import type {
  IAccounting_Bucket_BucketType,
  IAccountModel,
  IDebtOutBalanceModel,
  IPersonOutDebt,
  IPersonOutModel} from "~/gql/graphql";
import {
  IRefundReasonEnumContainer_RefundReason
} from "~/gql/graphql";
import { IApi_PaymentTransactionType } from "~/gql/graphql";
import type { ClassifiedDLE } from "~/models/models/dle";
import { isTotalAdjustmentDLE } from "~/models/models/dle";
import { OverflowTooltip } from "../../widgets/OverflowTooltip";

export interface AdjustedBalance {
  id: string;
  fees: number | undefined;
  principal: number | undefined;
  costs: number | undefined;
  interest: number | undefined;
  overcharges: number | undefined;
  total: number;
  notes: string | undefined | null;
  date: number | string;
}

export function calculateAdjustedBalance(dles: ClassifiedDLE[]): AdjustedBalance[] {
  return dles.filter(isTotalAdjustmentDLE).map(dle => {
    const totalEntry = dle.total_to_collect_adjustment_log_entry;

    const balances: Record<IAccounting_Bucket_BucketType, number> = chain(totalEntry.snapshot?.buckets)
      .groupBy(b => b?.bucket_type)
      .map<[IAccounting_Bucket_BucketType, number]>((buckets, bucketType) => [
        bucketType as IAccounting_Bucket_BucketType,
        sum(buckets.map(b => b?.amount?.amount))
      ])
      .fromPairs()
      .value() as Record<IAccounting_Bucket_BucketType, number>;
    return {
      fees: balances["FEES"],
      principal: balances["PRINCIPAL"],
      costs: balances["COSTS"],
      interest: balances["INTEREST"],
      overcharges: balances["OVERCHARGES"],
      total: chain(balances)
        .map(amount => amount)
        .sum()
        .value(),
      id: dle.id,
      notes: totalEntry.creditor_note ?? totalEntry.description ?? "No note.",
      date: formatTimestamp(dle.meta?.time_created)
    };
  });
}

/**
 * For multiple debts from the same company, adjusts company names to include a number to disambiguate in UI.
 * @param personOut
 * @param account
 */
export function disambiguateCompanyNames(
  personOut: IPersonOutModel,
  account: IAccountModel
): IPersonOutDebt[] {
  return chain(personOut.debts ?? [])
    .compact()
    .groupBy(debt => debt.creditor?.company_name)
    .flatMap((debts, companyName) => {
      if (debts.length > 1) {
        return debts.map((debt, idx) => {
          if (debt.id === account.id) {
            // don't change the name of company which debt we're looking at
            return debt;
          }
          return {
            ...debt,
            creditor: {
              ...debt.creditor,
              company_name: `${companyName} #(${idx + 1})`
            }
          };
        });
      } else {
        return debts;
      }
    })
    .value();
}

export function getLastChargeDate(debtOutBalance: IDebtOutBalanceModel): string {
  const day = debtOutBalance.recent_transaction_info?.last_payment_date?.day;
  const month = debtOutBalance.recent_transaction_info?.last_payment_date?.month;
  const year = debtOutBalance.recent_transaction_info?.last_payment_date?.year;

  let dateFormatted = "None";
  let date;
  if (day && month && year) {
    date = new Date(year, month - 1, day);

    if (isValidDate(date)) {
      dateFormatted = formatDate(date);
    }
  }
  return dateFormatted;
}

export function getTransactionType(transactionType: string | undefined | null): string | React.ReactElement {
  switch (transactionType) {
    case IApi_PaymentTransactionType.Ach:
      return "ACH";
    case IApi_PaymentTransactionType.AchReturn:
      return "ACH Return";
    case IApi_PaymentTransactionType.Authorization:
      return "Authorization";
    case IApi_PaymentTransactionType.Card:
      return "Card";
    case IApi_PaymentTransactionType.Check:
      return "Check";
    case IApi_PaymentTransactionType.Other:
      return "Other";
    case IApi_PaymentTransactionType.Paypal:
      return "Paypal";
    case IApi_PaymentTransactionType.Refund:
      return "Refund";
    case IApi_PaymentTransactionType.CrowdFlowerReward:
      return <OverflowTooltip title={"Crowd Flower Reward"}>CrowdFlowRew...</OverflowTooltip>;
    case IApi_PaymentTransactionType.DirectPaymentToCreditor:
      return "D2C";
    case IApi_PaymentTransactionType.PromotionalCredit:
      return "PromoCredit";
    case IApi_PaymentTransactionType.SuperRewardsCredit:
      return <OverflowTooltip title={"Super Rewards Credit"}>SupRewardCred...</OverflowTooltip>;
    case IApi_PaymentTransactionType.WireTransfer:
      return "Wire Transfer";
    case IApi_PaymentTransactionType.WireTransferReturn:
      return <OverflowTooltip title={"Wire Transfer Return"}>WireTransRet...</OverflowTooltip>;
    default:
      return "";
  }
}
