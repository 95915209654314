import Stack from "@mui/material/Stack";
import * as React from "react";
import { Block } from "../blocks/Block";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import InfoIcon from "@mui/icons-material/Info";
import Avatar from "@mui/material/Avatar";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Popper from "@mui/material/Popper";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import _ from "lodash";
import { OverflowTooltip } from "../../widgets/OverflowTooltip";
import { ThreeDotsButton } from "../../widgets/ThreeDotsButton";
import { MonetaryValue } from "../widgets/MonetaryValue";
import type { IAccountModel, IDebtOutBalanceModel, IPersonOutModel, Maybe } from "~/gql/types";
import type { Variant } from "@mui/material/styles/createTypography";
import type { BalanceDetailRow, IBalanceDetailTableProps } from "../blocks/BalanceDetails";
import { getBalanceDetails } from "../blocks/BalanceDetails";
import { ArrowedPopper } from "../../widgets/ArrowedPopper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import makeStyles from "@mui/styles/makeStyles";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import pluralize from "pluralize";
import { ModalCreditors } from "./ModalCreditors";
import { StyledPopper } from "./AccountView";
import { useHistory } from "react-router-dom";

const options = ["Create Part Payment", "Delayed 1 Time Payment", "Accept Payment Over the Phone"];

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#0074D91A"
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0
  }
}));

const TableCellCustom = styled(TableCell)`
  &.MuiTableCell-root {
    padding: 8px;
  }
`;

export interface IHeaderDebtorProps {
  account: IAccountModel;
  personOut: Maybe<IPersonOutModel>;
  openDrawer: boolean;
  titleTypography?: Variant;
  debtOutBalance: IDebtOutBalanceModel;
  width: number;
  isViewPlan: boolean;
  isBK: boolean;
  setIsBK: (value: boolean) => void;
  isJustTheCurrentAccount: boolean;
}

export function HeaderDebtor({
  account,
  personOut,
  openDrawer,
  titleTypography,
  debtOutBalance,
  width,
  isViewPlan,
  isBK,
  setIsBK,
  isJustTheCurrentAccount
}: IHeaderDebtorProps): React.ReactElement | null {
  const theme = useTheme();
  const history = useHistory();

  const useStyles = makeStyles({
    button: {
      "&:hover": {
        backgroundColor: theme.palette.primary.dark
      }
    },
    arrow: {
      "&:hover": {
        backgroundColor: theme.palette.common.white
      }
    }
  });
  const debt = account.debt;
  const classButton = useStyles();
  const [open, setOpen] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const anchorRef = React.useRef<HTMLInputElement>(null);
  const [isOpenModalCreditors, setOpenModalCreditors] = React.useState(false);

  const [isPopOverOpen, setRawPopOverOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [arrowRef, setArrowRef] = React.useState<{ arrowRef: React.BaseSyntheticEvent } | null>(null);
  const handleArrowRef = (node: React.BaseSyntheticEvent): void => setArrowRef({ arrowRef: node });
  const buttonId = isOpenModalCreditors ? "multiple-accounts-creditor" : undefined;
  const setPopOverOpen = (isOpen: boolean, elem: HTMLElement | null) => {
    setAnchorEl(elem);
    setRawPopOverOpen(isOpen);
  };

  const handleClickBalanceDetailsPopOver = (evt: React.BaseSyntheticEvent) => {
    setAnchorEl(evt.currentTarget);
    setRawPopOverOpen(true);
  };

  if (!debt) {
    return null;
  }

  const balanceDetails = getBalanceDetails(debtOutBalance);

  const rows: BalanceDetailRow[] = [
    balanceDetails.totalAmountAtPlacement,
    balanceDetails.totalAmountToCollect,
    balanceDetails.outstandingAmountsToCollect
  ];

  const handleMenuItemClick = (event: React.SyntheticEvent, index: number) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: React.ChangeEvent<HTMLInputElement> | any) => {
    setOpen(false);

    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
  };

  function getTruncatedCreditor(): React.ReactElement {
    const truncatedCreditor = _.truncate(`${account.creditor?.company_name}` ?? "", {
      length: openDrawer ? 18 : 31,
      omission: "…"
    });

    const companyName = `${account.creditor?.company_name}` ?? "";
    return (
      <>
        {truncatedCreditor.length > companyName.length ? (
          <OverflowTooltip title={companyName}>
            <Typography variant={titleTypography ? "h5" : "h2"}>
              {width <= 950 ? companyName : truncatedCreditor}
            </Typography>
          </OverflowTooltip>
        ) : (
          <Typography variant={titleTypography ? "h5" : "h2"}>
            {width <= 950 ? truncatedCreditor : companyName}
          </Typography>
        )}

        {account.creditor?.original_brand?.name !== account.creditor?.company_name && (
          <OverflowTooltip title={`Original Creditor: ${account.creditor?.original_brand?.name ?? ""}`}>
            <InfoIcon />
          </OverflowTooltip>
        )}
      </>
    );
  }

  function handleGoToAuthenticateCallerPage(): void {
    history.push(`/app/accounts/authenticate/${account.id}`);
  }

  function dialogVisible(): boolean {
    return isViewPlan || isBK;
  }

  return (
    <Box>
      <Block embedded paddingTopHeader="0px" isCard noBottomPadding={dialogVisible()}>
        <Grid container pl={1.75} pr={2.5} pb={dialogVisible() ? 0 : 1}>
          <Grid item xs={12} md={8} lg={6}>
            <Typography variant={titleTypography ? "h4" : "h1"}>
              <Stack direction={"row"}>
                {debt?.person?.name?.first_name ?? "Loading..."} {debt?.person?.name?.last_name}
                {!isJustTheCurrentAccount && debtOutBalance && personOut && personOut?.debts?.length > 0 && (
                  <Box
                    sx={{ cursor: "pointer" }}
                    ml={2}
                    mt={0.3}
                    onClick={evt => {
                      setAnchorEl(evt.currentTarget);
                      setOpenModalCreditors(true);
                    }}>
                    <Typography variant="subtitle1" color={theme.palette.primary.dark}>
                      + {pluralize("other account", personOut?.debts?.length - 1, true)}
                    </Typography>
                  </Box>
                )}
              </Stack>
            </Typography>
            <Stack direction="row" mt={2} mb={2}>
              <Stack>
                <Typography variant="body1">TA #: {debt.account_number}</Typography>
              </Stack>
              {personOut?.additional_information?.user_account && (
                <Stack>
                  <CheckCircleOutlineIcon sx={{ color: theme.palette.success.light }} />
                </Stack>
              )}
            </Stack>
            {!dialogVisible() && (
              <Stack spacing={2} direction="row">
                <Stack direction={"row"}>
                  <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button">
                    <Button
                      size={"large"}
                      sx={{
                        textTransform: "none",
                        backgroundColor: theme.palette.primary.dark,
                        color: theme.palette.common.white,
                        borderTopLeftRadius: "3px",
                        borderBottomLeftRadius: "3px",
                        borderBottomRightRadius: "0px",
                        borderTopRightRadius: "0px",
                        padding: "0px 16px",
                        width: "200px",
                        maxHeight: "43px"
                      }}
                      className={classButton.button}>
                      Create Payment Plan
                    </Button>
                    <Button
                      sx={{
                        textTransform: "none",
                        backgroundColor: theme.palette.common.white,
                        marginLeft: 0.2,
                        color: theme.palette.primary.dark,
                        borderTopLeftRadius: "0px",
                        borderBottomLeftRadius: "0px",
                        borderBottomRightRadius: "3px",
                        borderTopRightRadius: "3px",
                        border: `1px solid ${theme.palette.primary.dark}`,
                        minWidth: "170px",
                        maxHeight: "43px",
                        padding: 0
                      }}
                      className={classButton.arrow}
                      size="small"
                      aria-controls={open ? "split-button-menu" : undefined}
                      aria-expanded={open ? "true" : undefined}
                      aria-haspopup="menu"
                      onClick={handleToggle}>
                      {!open ? (
                        <ArrowDropDownIcon sx={{ fontSize: "2rem" }} />
                      ) : (
                        <ArrowDropUpIcon sx={{ fontSize: "2rem" }} />
                      )}
                    </Button>
                  </ButtonGroup>
                </Stack>
                <Stack direction="row">
                  <Button
                    variant="outlined"
                    sx={{
                      textTransform: "none",
                      color: theme.palette.primary.dark,
                      borderColor: theme.palette.primary.dark,
                      width: "209px",
                      maxHeight: "43px"
                    }}
                    onClick={() => handleGoToAuthenticateCallerPage()}>
                    Verify this Caller
                  </Button>
                </Stack>
                <Stack direction="row">
                  <Button
                    onClick={() => setIsBK(true)}
                    variant="outlined"
                    size="large"
                    sx={{
                      textTransform: "none",
                      color: theme.palette.primary.dark,
                      borderColor: theme.palette.primary.dark,
                      minWidth: "180px",
                      maxHeight: "43px"
                    }}>
                    Log a Bankruptcy
                  </Button>
                </Stack>
                <Stack direction="row" sx={{ marginLeft: "-1px !important" }} alignItems="center">
                  <ThreeDotsButton />
                </Stack>
              </Stack>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            lg={6}
            display="flex"
            flexDirection={"column"}
            alignItems={"flex-end"}
            justifyContent="flex-start">
            <Stack
              color={theme.palette.primary.dark}
              sx={{ cursor: "pointer" }}
              onClick={handleClickBalanceDetailsPopOver}>
              <MonetaryValue
                variant={titleTypography ? "h6" : "h2"}
                monetaryAmount={{ amount: account.balance?.amount, currency: account.balance?.currency }}
              />
            </Stack>

            <Stack direction="row">
              <Typography variant={"h2"} display={"flex"} mt={1.875} mb={1.625}>
                {getTruncatedCreditor()}
              </Typography>
            </Stack>
            {!dialogVisible() && (
              <Stack direction="row" alignItems="center">
                <Stack mr={2}>
                  <Typography variant="subtitle1">Debt Status:</Typography>
                </Stack>
                <Stack direction="row">
                  <Avatar
                    sx={{
                      color: theme.palette.common.white,
                      backgroundColor: theme.palette.grey[700],
                      width: "100%",
                      px: 2,
                      py: 1,
                      fontSize: theme.typography.h4,
                      borderRadius: "4px"
                    }}
                    variant="rounded">
                    {debt?.status}
                  </Avatar>
                </Stack>
              </Stack>
            )}
          </Grid>

          {/* We will use this piece of code in the future when we build the create a dispute button flow,
          now authenticate caller button is in it's place */}
          {/* <Stack zIndex={2}>
            <Popper
              open={openThreeDots}
              anchorEl={anchorRefThreeDots.current}
              role={undefined}
              transition
              disablePortal>
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin: "bottom",
                    position: "relative"
                  }}>
                  <Paper
                    sx={{
                      position: "relative",
                      left: "713px",
                      top: "217px",
                      boxShadow: "0px 0px 2px 0px rgb(0 0 0 / 75%)",
                      padding: "8px"
                    }}>
                    <ClickAwayListener onClickAway={() => handleCloseThreeDots()}>
                      <Stack>
                        {optionsMenuThreeDots.map((item, index) => {
                          return (
                            <Button
                              sx={{
                                justifyContent: "flex-start",
                                color: theme.palette.common.black,
                                textTransform: "none",
                                fontWeight: 600
                              }}
                              disabled={index > 0}
                              variant="text"
                              onClick={() => handleOpenItemMenuThreeDots(item)}>
                              {item}
                            </Button>
                          );
                        })}
                      </Stack>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Stack> */}

          <Stack zIndex={2}>
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin: "bottom",
                    position: "relative"
                  }}>
                  <Paper sx={{ position: "relative", left: "197px", top: "144px" }}>
                    <ClickAwayListener onClickAway={e => handleClose(e)}>
                      <MenuList id="split-button-menu" sx={{ padding: 0 }}>
                        {options.map((option, index) => (
                          <MenuItem
                            key={option}
                            selected={index === selectedIndex}
                            sx={{ fontWeight: 600 }}
                            onClick={event => handleMenuItemClick(event, index)}>
                            {option}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Stack>

          <ArrowedPopper
            open={isOpenModalCreditors}
            onClose={() => {
              setOpenModalCreditors(false);
              setAnchorEl(null);
            }}
            anchorEl={anchorEl}
            placement={"bottom"}>
            <ClickAwayListener onClickAway={() => setOpenModalCreditors(false)}>
              <>
                {personOut && personOut?.debts?.length > 0 && (
                  <ModalCreditors account={account} personOut={personOut} />
                )}
              </>
            </ClickAwayListener>
          </ArrowedPopper>

          <ArrowedPopper
            onRightEdge
            open={isPopOverOpen}
            onClose={() => setPopOverOpen(false, null)}
            anchorEl={anchorEl}>
            <Block
              paddingTitle="16px 16px 0px 16px"
              paddingTopContent={"0px !important"}
              title="Balance Details"
              noHorizontalPadding
              noBottomPadding
              titleTypography="h5">
              <BalanceDetailsTable rows={rows} />
            </Block>
          </ArrowedPopper>
        </Grid>
      </Block>
    </Box>
  );
}

function BalanceDetailsTable({ rows }: IBalanceDetailTableProps): React.ReactElement {
  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell> </TableCell>
              <TableCell>
                <Typography variant="body2">Principal</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">Interest</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">Fees</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">Cost</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">Total</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(({ label, principal, interest, fees, cost, total }) => (
              <StyledTableRow key={label}>
                <TableCell>{label}</TableCell>
                <TableCellCustom>
                  <MonetaryValue variant="body1" monetaryAmount={principal} />
                </TableCellCustom>
                <TableCellCustom>
                  <MonetaryValue variant="body1" monetaryAmount={interest} />
                </TableCellCustom>
                <TableCellCustom>
                  <MonetaryValue variant="body1" monetaryAmount={fees} />
                </TableCellCustom>
                <TableCellCustom>
                  <MonetaryValue variant="body1" monetaryAmount={cost} />
                </TableCellCustom>
                <TableCellCustom>
                  <MonetaryValue variant="body1" monetaryAmount={total} />
                </TableCellCustom>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
