import { ContentCopy as ContentCopyIcon } from "@mui/icons-material";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import type { Variant } from "@mui/material/styles/createTypography";
import Typography from "@mui/material/Typography";
import _ from "lodash";
import type { ReactChild, ReactElement } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { OverflowTooltip } from "./OverflowTooltip";

interface CopyTextProps {
  value: string;
  displayValue?: ReactChild;
  truncate?: number;
  variant?: Variant;
  onCopy?: () => void;
}

export function CopyText({ value, displayValue, truncate, variant, onCopy }: CopyTextProps): ReactElement {
  const truncated = _.truncate(value, {
    length: truncate,
    omission: "…"
  });
  const isTruncated = truncated !== value;
  displayValue ??= truncate ? (
    <Typography variant={variant}>{truncated}</Typography>
  ) : (
    <Typography variant={variant}>{value}</Typography>
  );
  return (
    <Stack direction={"row"} spacing={1}>
      {isTruncated ? <OverflowTooltip title={value}>{displayValue}</OverflowTooltip> : displayValue}
      <Box sx={{ cursor: "pointer" }}>
        <CopyToClipboard onCopy={onCopy} text={value}>
          <ContentCopyIcon fontSize={"small"} />
        </CopyToClipboard>
      </Box>
    </Stack>
  );
}
