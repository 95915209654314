import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorIcon from "@mui/icons-material/Error";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import * as React from "react";
import type { IAccountModel, IDebtLogEntryModel, IUnifiedPaymentEntry, Maybe } from "~/gql/types";
import { formatTimestamp } from "../../../utils/formats";
import { MonetaryValue } from "../widgets/MonetaryValue";
import { useTheme } from "@mui/material/styles";
import { getTransactionType } from "./helpers";
import { ArrowedPopper } from "../../widgets/ArrowedPopper";
import type { VirtualElement } from "@popperjs/core";
import { PaymentDetails } from "./PaymentDetails";
import _ from "lodash";

export interface IPaymentHistoryProps {
  account: IAccountModel;
  logEntries: IDebtLogEntryModel[] | undefined;
}

export function getPaymentLogEntry(
  paymentEntryId: string | undefined | null,
  logEntries: IDebtLogEntryModel[] | undefined
) {
  return _.find(logEntries, item => item?.id === paymentEntryId);
}

function PaymentEntryStatus({ entry }: { entry: Maybe<IUnifiedPaymentEntry> }): React.ReactElement {
  const theme = useTheme();
  return entry?.is_pending ? (
    <PendingActionsIcon />
  ) : entry?.is_successful ? (
    <CheckCircleOutlineIcon sx={{ color: theme.palette.success.dark }} />
  ) : (
    <ErrorIcon sx={{ color: theme.palette.warning.light }} />
  );
}

export const PaymentHistory = ({ account, logEntries }: IPaymentHistoryProps): React.ReactElement => {
  const theme = useTheme();
  const allEntries = account.unified_payment_entries?.slice() || [];

  allEntries?.sort(
    (d1, d2) => new Date(d2?.payment_time ?? "").getTime() - new Date(d1?.payment_time ?? "").getTime()
  );

  const firstLoadCount = 2;
  const nextLoadCount = 5;
  const countBeforeLoadingRemaining = firstLoadCount + nextLoadCount;

  const firstEntries = allEntries?.slice(0, firstLoadCount);
  const [entries, setEntries] = React.useState(firstEntries);

  function loadNextEntries() {
    const nextEntries = allEntries?.slice(0, countBeforeLoadingRemaining);
    setEntries(nextEntries);
  }

  function loadRemaining() {
    const allItemsRemaining = allEntries?.slice();
    setEntries(allItemsRemaining);
  }
  const [anchorEl, setAnchorEl] = React.useState<VirtualElement | null>(null);
  const [isPopOverPaymentDetailsOpen, setPopOverPaymentDetailsOpen] = React.useState(false);
  const [selectedTransaction, setSeletedTransaction] = React.useState<IUnifiedPaymentEntry>();

  const handlerClosePaymentDetails = (isOpen: boolean, elem: HTMLElement | null) => {
    setAnchorEl(elem);
    setPopOverPaymentDetailsOpen(isOpen);
  };

  function ifTransactionTypeWithoutDetails(entry: Maybe<IUnifiedPaymentEntry>): boolean {
    const transactionType = getTransactionType(entry?.transaction_type);
    return (
      transactionType === "Authorization" ||
      transactionType === "Other" ||
      transactionType === "Paypal" ||
      transactionType === "Wire Transfer" ||
      transactionType === "Wire Transfer Return"
    );
  }

  const handleClickOpenPaymentDetails = (
    evt: React.BaseSyntheticEvent,
    entry: IUnifiedPaymentEntry
  ): void => {
    const isTransactionTypeWithoutDetails = ifTransactionTypeWithoutDetails(entry);

    if (isTransactionTypeWithoutDetails) return;
    setAnchorEl(evt.currentTarget);
    setPopOverPaymentDetailsOpen(true);
    setSeletedTransaction(entry);
  };

  function removeModalPaymentDetailsOnScroll(): void {
    setPopOverPaymentDetailsOpen(false);
  }

  React.useEffect(() => {
    window.addEventListener("scroll", removeModalPaymentDetailsOnScroll);
    return () => window.removeEventListener("scroll", removeModalPaymentDetailsOnScroll);
  });

  function getUnifiedPaymentEntry(reversedPaymentLogEntry: string | null | undefined) {
    return allEntries.find(entry => entry?.payment_entry_id === reversedPaymentLogEntry);
  }

  const logEntryByIdSelectedProp = getPaymentLogEntry(selectedTransaction?.payment_entry_id, logEntries);
  const reversedUnifiedPaymentEntryProp = getUnifiedPaymentEntry(
    logEntryByIdSelectedProp?.payment_log_entry?.reversed_payment_log_entry_id
  );

  return (
    <Stack maxHeight="24em" spacing={2}>
      <TableContainer>
        <Table>
          <TableBody>
            {entries?.map(entry => {
              const transactionType = getTransactionType(entry?.transaction_type);
              const isTransactionTypeWithoutDetails = ifTransactionTypeWithoutDetails(entry);

              const logEntry = getPaymentLogEntry(entry?.payment_entry_id, logEntries);

              const reversedUnifiedPaymentEntry = getUnifiedPaymentEntry(
                logEntry?.payment_log_entry?.reversed_payment_log_entry_id
              );
              const isACHReversedTransactionType =
                transactionType === "Refund" && reversedUnifiedPaymentEntry?.transaction_type === "ACH";
              return (
                <TableRow key={`${entry?.amount}-${entry?.payment_time}`}>
                  <TableCell>
                    {entry?.transaction_type ? (
                      <Typography
                        sx={{
                          color: isTransactionTypeWithoutDetails
                            ? theme.palette.common.black
                            : theme.palette.primary.dark,
                          cursor: isTransactionTypeWithoutDetails ? "unset" : "pointer"
                        }}
                        onClick={evt => handleClickOpenPaymentDetails(evt, entry)}
                        variant="body1">
                        {isACHReversedTransactionType ? "ACH Chargeback" : transactionType}
                      </Typography>
                    ) : (
                      <>None</>
                    )}
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">{formatTimestamp(entry?.payment_time)}</Typography>
                  </TableCell>
                  <TableCell>
                    <MonetaryValue variant="body1" monetaryAmount={entry?.amount} />
                  </TableCell>
                  <TableCell>
                    <PaymentEntryStatus entry={entry} />
                  </TableCell>
                </TableRow>
              );
            })}
            {entries?.length === 0 && (
              <Stack mb={8.5} position={"relative"} top={"30px"}>
                <Typography variant="body1" textAlign="center">
                  No entries
                </Typography>
              </Stack>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {selectedTransaction !== undefined && logEntryByIdSelectedProp !== undefined && (
        <ArrowedPopper
          open={isPopOverPaymentDetailsOpen}
          onClose={() => handlerClosePaymentDetails(false, null)}
          anchorEl={anchorEl}
          placement={"left"}>
          <PaymentDetails
            entry={selectedTransaction}
            reversedUnifiedPaymentEntry={reversedUnifiedPaymentEntryProp}
            logEntry={logEntryByIdSelectedProp}
          />
        </ArrowedPopper>
      )}

      {entries !== undefined && entries.length !== allEntries?.length && entries.length > 0 && (
        <Stack direction="row" mt={"1em"} pb={1.5}>
          <Button
            sx={{
              margin: "0 auto",
              textTransform: "none",
              color: theme.palette.primary.dark,
              borderColor: theme.palette.primary.dark
            }}
            onClick={() =>
              entries.length >= countBeforeLoadingRemaining ? loadRemaining() : loadNextEntries()
            }
            variant="outlined">
            {entries !== undefined && entries?.length <= firstLoadCount
              ? `Load Next ${nextLoadCount}`
              : "Load Remaining"}
          </Button>
        </Stack>
      )}
    </Stack>
  );
};
