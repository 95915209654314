import _ from "lodash";
import type { ReactElement } from "react";
import * as React from "react";
import { useParams } from "react-router-dom";
import { DisputeTaskViewByBusinessKeyQuery } from "~/core/components/domain/tasks/DisputeTaskView";
import { useSafeQuery } from "~/core/utils/apollo";
import type { ICamundaTaskModel, IDisputeTaskModel, IDisputeTaskView } from "~/gql/graphql";
import { CamundaConstants } from "~/models/models/constants";
import { ShowTask } from "./ShowTask";

export interface ShowTaskByBusinessKeyRouteParams {
  businessKey: string;
}

export function ShowTaskByBusinessKey(): ReactElement {
  const { businessKey } = useParams<ShowTaskByBusinessKeyRouteParams>();
  const { loading, data } = useSafeQuery(DisputeTaskViewByBusinessKeyQuery, {
    variables: {
      name: CamundaConstants.taskNames.DEBT_VERIFICATION,
      businessKey
    },
    pollInterval: 60_000
  });
  
  function buildShowTaskData(): IDisputeTaskView {
    const tasks: ICamundaTaskModel[] = _.compact(data?.tasks ?? []);
    const firstTaskResult: IDisputeTaskModel | undefined = tasks?.[0];
    return {
      task: firstTaskResult
    };
  }
  
  return <ShowTask isLoading={loading} data={buildShowTaskData()} />;
}
