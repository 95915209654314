import Stack from "@mui/material/Stack";
import * as React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { gql } from "@apollo/client";
import _ from "lodash";
import type { IAccountModel } from "~/gql/types";
import { Stepper } from "./Stepper";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import type {
  SelectChangeEvent} from "@mui/material";
import {
  Alert,
  AlertTitle,
  Avatar,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  Modal,
  Radio,
  RadioGroup,
  Snackbar,
  TextField,
  Typography
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { CollapseInStepper } from "./CollapseInStepper";
import { useSafeQuery } from "~/core/utils/apollo";
import { OverflowTooltip } from "../../widgets/OverflowTooltip";
import { MonetaryValue } from "../widgets/MonetaryValue";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { useHistory } from "react-router-dom";
import { RadioChooserStepper, RadioChooserStepperAddress, RadioChooserEmailPhone } from "./RadioChoosers";
import { formatTimestamp } from "~/core/utils/formats";
import { useCrmApi } from "~/core/contexts/ApiContext";
export interface IAuthStepper {
  account: IAccountModel;
  ssn_last4: string | null | undefined;
}

const useStyles = makeStyles({
  iconClass: {
    "& .MuiAlert-icon": {
      color: "#FFF",
      backgroundColor: "rgba(0, 116, 217, 1)",
      padding: "11px",
      fontSize: "1.75em",
      "& .MuiSvgIcon-root": {
        position: "relative"
      }
    }
  }
});

export const CreditorsQueryAuth = gql(`
query CreditorsQueryAuth($creditorId: ID!) {
  creditor: Creditor(id: $creditorId) {
    creditor_settings {
      customer_call_authentication_settings{
        criteria{
          options
        }
      }
    }
  }
}
`);

const PersonOutQueryAdditionalInfo = gql(`
query PersonOutQuery($personId: ID!) {
  personOut: PersonOut(id: $personId) {
    ...PersonOutModel
  }
}
`);

export function AuthStepper({ account, ssn_last4 }: IAuthStepper): React.ReactElement {
  const { data } = useSafeQuery(CreditorsQueryAuth, {
    variables: { creditorId: account.debt?.creditor?.id ?? "" }
  });

  const { data: personOut } = useSafeQuery(PersonOutQueryAdditionalInfo, {
    variables: { personId: account.debt?.person?.id ?? "" }
  });
  const history = useHistory();
  const crmApi = useCrmApi();
  const [isEnabledStepMiniMiranda, setEnableStepMiniMiranda] = React.useState(false);

  const [isCollapse, setIsCollapse] = React.useState<Array<{ index: number; isCheck: boolean }>>([
    { index: 0, isCheck: false }
  ]);

  function handleChangeSimpleStepper(index: number) {
    setIndexToDelete(index);
  }

  const handleChangeCurrentAddress = (event: SelectChangeEvent, index: number) => {
    if (index === indexPreviousSelectGroup) {
      setIndexToDelete(index);
    } else {
      setIndexPreviousGroup(index);
    }
  };

  const handleChangePreviousAddress = (event: SelectChangeEvent, index: number) => {
    if (index === indexPreviousSelectGroup) {
      setIndexToDelete(index);
    } else {
      setIndexPreviousGroup(index);
    }
  };

  const [email, setEmail] = React.useState("");
  const [emailValue, setEmailValue] = React.useState("");

  const handleChangeEmail = (event: SelectChangeEvent, index: number) => {
    if (index === indexPreviousSelectGroup) {
      setIndexToDelete(index);
    } else {
      setIndexPreviousGroup(index);
    }
    setEmailValue(event.target.value);
    setEmail(event.target.value);
  };

  const [phone, setPhone] = React.useState("");
  const [phoneValue, setPhoneValue] = React.useState("");

  const [indexPreviousSelectGroup, setIndexPreviousGroup] = React.useState(-1);

  const [phonesFormatted, setPhonesFormatted] = React.useState<Array<{ phone_number: string }> | undefined>([]);

  React.useEffect(() => {
    const valuePhones = account?.debt?.person?.phones;
    const phonesArrayFormatted = valuePhones?.map(item => {
      const itemPhoneNumber = item?.phone_number?.replaceAll("-", "");
      let valueFormatted;
      if (itemPhoneNumber?.length === 11) {
        valueFormatted = {
          phone_number: `${itemPhoneNumber?.slice(0, 1)}-${itemPhoneNumber?.slice(
            1,
            4
          )}-${itemPhoneNumber?.slice(4, 7)}-${itemPhoneNumber?.slice(7)}`
        };
      } else {
        valueFormatted = {
          phone_number: `${itemPhoneNumber?.slice(0, 3)}-${itemPhoneNumber?.slice(
            3,
            6
          )}-${itemPhoneNumber?.slice(6)}`
        };
      }

      return valueFormatted;
    });
    setPhonesFormatted(phonesArrayFormatted);
  }, [account?.debt?.person?.phones]);

  const [indexToDelete, setIndexToDelete] = React.useState(-1);

  const handleChangePhone = (event: SelectChangeEvent, index: number) => {
    if (index === indexPreviousSelectGroup) {
      setIndexToDelete(index);
    } else {
      setIndexPreviousGroup(index);
    }
    setPhoneValue(event.target.value);
    setPhone(event.target.value);
  };

  const [isOnlySearch, setOnlySearch] = React.useState(false);
  const theme = useTheme();
  const classes = useStyles();

  const [settingsVerify, setSettingsVerify] = React.useState(
    data?.creditor?.creditor_settings?.customer_call_authentication_settings?.criteria
  );

  const [selectedOptions, setSelectedOptions] = React.useState<{
    [key: string]: { index: number; isCheck: boolean };
  }>({});

  const [reload, setReload] = React.useState(false);

  function isOptionDisabled(option: string, criterionIndex: number): boolean {
    const isDisabled =
      selectedOptions?.[option] !== undefined &&
      selectedOptions[option]?.index !== criterionIndex &&
      selectedOptions[option]?.isCheck;

    return Boolean(isDisabled);
  }

  React.useEffect(() => {
    setSettingsVerify(data?.creditor?.creditor_settings?.customer_call_authentication_settings?.criteria);
    settingsVerify?.map((item, index) => {
      isCollapse[index] = { index: index, isCheck: false };
    });
    setIsCollapse(isCollapse);
  }, [data]);

  function toggleUnableVerify(): void {
    setOpenModalUnableVerify(true);
  }

  function toggleDisable(option: string, criterionIndex: number, isRemove?: boolean): void {
    const isDisabled =
      selectedOptions?.[option] !== undefined &&
      selectedOptions[option]?.index !== criterionIndex &&
      selectedOptions[option]?.isCheck;

    if (isDisabled) {
      return;
    }

    if (isRemove) {
      selectedOptions[option] = { index: criterionIndex, isCheck: false };
      setSelectedOptions(selectedOptions);
      setReload(!reload);
      return;
    }
    for (const i in selectedOptions) {
      if (selectedOptions[i]?.index === criterionIndex) {
        selectedOptions[i] = { index: criterionIndex, isCheck: false };
      }
    }
    selectedOptions[option] = { index: criterionIndex, isCheck: true };
    setSelectedOptions(selectedOptions);
    setReload(!reload);
  }

  function toggleCollapse(indexCollapse: number): void {
    settingsVerify?.map((item, index) => {
      if (indexCollapse === index) {
        isCollapse[index] = { index: index, isCheck: !isCollapse?.[index]?.isCheck };
      }
    });

    setIsCollapse(isCollapse);
    setReload(!reload);
  }

  function countSettingsSelected(): number {
    let counter = 0;
    settingsVerify?.map((item, criterionIndex) => {
      for (const i in selectedOptions) {
        if (selectedOptions[i]?.index === criterionIndex) {
          if (selectedOptions[i]?.isCheck) {
            counter++;
          }
        }
      }
    });
    return counter;
  }

  function isEnabledButtonNext(): boolean {
    const counter = countSettingsSelected();

    if (settingsVerify?.length === counter) {
      return false;
    } else {
      return true;
    }
  }

  React.useEffect(() => {
    const counter = countSettingsSelected();
    if (settingsVerify?.length === counter) {
      setOpenToast(true);
    }
  }, [reload]);

  const [isOpenToast, setOpenToast] = React.useState(false);
  const [isOpenModalUnableVerify, setOpenModalUnableVerify] = React.useState(false);

  function handleClickCancelUnableVerify(): void {
    setOpenModalUnableVerify(false);
  }

  function startOver(): void {
    for (const i in selectedOptions) {
      selectedOptions[i] = { index: Number(i), isCheck: false };
    }

    setSelectedOptions(selectedOptions);
    setReload(!reload);
  }

  const [isOpenModalStartOver, openModalStartOver] = React.useState(false);

  function getCreditorReference() {
    const debt = account.debt;
    const reference = _.find(personOut?.personOut?.additional_information?.references, function (reference) {
      return reference?.creditor_id === debt?.creditor?.id;
    });
    return reference?.reference;
  }

  function handleConfirmModalMiniMiranda(): void {
    history.push(`/app/accounts/${account.id}`);
    window.scrollTo(0, 0);
  }

  const currentAddressFiltered = account.debt?.person?.addresses?.filter(item => item?.meta?.is_active);
  const previousAddressFiltered = account.debt?.person?.addresses?.filter(item => !item?.meta?.is_active);

  const currentAddressBody = currentAddressFiltered?.map(item => {
    const itemAddress = {
      streetLine1: item?.street_line1,
      streetLine2: item?.street_line2,
      streetLine3: item?.street_line3,
      city: item?.city,
      state: item?.state,
      zipcode: item?.zipcode,
      countryCode: item?.country_code,
      meta: { isActive: item?.meta?.is_active }
    };
    return itemAddress;
  });

  const previousAddressBody = previousAddressFiltered?.map(item => {
    const itemAddress = {
      streetLine1: item?.street_line1,
      streetLine2: item?.street_line2,
      streetLine3: item?.street_line3,
      city: item?.city,
      state: item?.state,
      zipcode: item?.zipcode,
      countryCode: item?.country_code,
      meta: { isActive: item?.meta?.is_active }
    };
    return itemAddress;
  });

  async function submitCallAuthentication() {
    const transactionDate = formatTimestamp(account.debt?.transaction_timestamp);
    const date = new Date(transactionDate);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    const transactionValue = isNaN(year) ? undefined : { year: year, month: month, day: day };
    const body = {
      personId: account?.debt?.person?.id,
      debtId: account?.debt?.id,
      direction: "INBOUND",
      lineType: "RECORDED",
      ...(selectedOptions["FULL_NAME"]?.isCheck && {
        fullName: {
          firstName: account.debt?.person?.name?.first_name,
          middleName: account.debt?.person?.name?.middle_name,
          lastName: account.debt?.person?.name?.last_name
        }
      }),
      ...(selectedOptions["CURRENT_ADDRESS"]?.isCheck && {
        currentAddress: currentAddressBody?.[0]
      }),
      ...(selectedOptions["DATE_OF_BIRTH"]?.isCheck && {
        dateOfBirth: {
          year: account.debt?.person?.date_of_birth?.year,
          month: account.debt?.person?.date_of_birth?.month,
          day: account.debt?.person?.date_of_birth?.day
        }
      }),
      ...(selectedOptions["PREVIOUS_ADDRESS"]?.isCheck && {
        previousAddress: previousAddressBody?.[0]
      }),
      ...(selectedOptions["YEAR_OF_BIRTH"]?.isCheck && {
        yearOfBirth: account.debt?.person?.date_of_birth?.year
      }),
      ...(selectedOptions["EMAIL_ADDRESS"]?.isCheck && {
        emailAddress: `${account.debt?.person?.emails[Number(emailValue) - 1]?.email}`
      }),
      ...(selectedOptions["PHONE_NUMBER"]?.isCheck && {
        phoneNumber: `${account.debt?.person?.phones[Number(phoneValue) - 1]?.phone_number}`
      }),
      ...(selectedOptions["TA_ACCOUNT_NUMBER"]?.isCheck && {
        taAccountNumber: `${account.debt?.account_number}`
      }),
      ...(selectedOptions["CREDITOR_REFERENCE"]?.isCheck && {
        creditorReference: getCreditorReference()
      }),
      ...(selectedOptions["TRANSACTION_ID"]?.isCheck && {
        transactionId: `${account.debt?.transaction_id}`
      }),
      ...(selectedOptions["SSN_LAST4"]?.isCheck && {
        ssnLast4: ssn_last4
      }),
      ...(selectedOptions["TRANSACTION_DATE"]?.isCheck && {
        transactionDate: transactionValue
      }),
      ...(selectedOptions["PRODUCT"]?.isCheck && {
        product: `${account.debt?.product}`
      })
    };

    const result = await crmApi
      .stepperControllerSubmitCallAuthentication({
        body: body,
        personId: account?.debt?.person?.id ?? ""
      })
      .then(data => {
        return data.data;
      });
    return result;
  }

  return (
    <>
      <>
        {!isOnlySearch && (
          <Stack position="relative" pt={"0px !important"}>
            <Stepper isEnabledStepMiniMiranda={isEnabledStepMiniMiranda} />
            <Grid container spacing={1} ml={1} mr={1} mt={1} width={"unset !important"}>
              {!isEnabledStepMiniMiranda && (
                <>
                  <Grid
                    xs={9}
                    item
                    sx={{
                      backgroundColor: theme.palette.common.white
                    }}>
                    <Stack
                      direction={"row"}
                      justifyContent="space-between"
                      mt={1}
                      pb={2.625}
                      mr={2.375}
                      sx={{ borderBottom: `1px solid ${theme.palette.grey[200]}` }}>
                      <Stack direction={"row"}>
                        <ArrowBackIcon
                          sx={{ color: theme.palette.primary.main, fontSize: "31px", cursor: "pointer" }}
                          onClick={() => history.push(`/app/accounts/${account.id}`)}
                        />
                        <Typography ml={1.75} sx={{ top: "4px", position: "relative" }} variant="h2">
                          Verify this Caller
                        </Typography>
                      </Stack>
                      <Stack direction={"row"}>
                        <Button
                          onClick={() => openModalStartOver(true)}
                          variant="text"
                          sx={{ textTransform: "none", color: theme.palette.primary.dark }}>
                          Start Over
                        </Button>
                        <Stack ml={2}>
                          <Button
                            onClick={() => toggleUnableVerify()}
                            variant="outlined"
                            sx={{ textTransform: "none", color: theme.palette.primary.dark }}>
                            UNABLE TO VERIFY
                          </Button>
                        </Stack>
                        <Stack ml={2}>
                          <Button
                            sx={{ backgroundColor: theme.palette.primary.dark }}
                            variant="contained"
                            disabled={isEnabledButtonNext()}
                            onClick={() => setEnableStepMiniMiranda(true)}>
                            NEXT
                          </Button>
                        </Stack>
                      </Stack>
                    </Stack>
                    <Box height={"48px"} marginTop={"16px"} mt={3.5} mb={1}>
                      <Alert
                        className={classes.iconClass}
                        sx={{
                          backgroundColor: theme.palette.common.white,
                          color: theme.palette.common.black,
                          position: "relative",
                          left: "-6px",
                          padding: "0px 15px",
                          paddingLeft: "0px !important",
                          marginRight: "10px",
                          marginLeft: "7px",

                          border: `2px solid ${theme.palette.primary.main}`
                        }}
                        severity="info">
                        <Typography marginTop={"6px"} variant={"h3"}>
                          {settingsVerify?.length} Verifications Needed
                        </Typography>
                      </Alert>
                    </Box>
                    <Stack pb={5} mr={2} ml={0} sx={{ borderBottom: `1px solid ${theme.palette.grey[200]}` }}>
                      {settingsVerify?.map((criterionOption, index: number) => {
                        return (
                          <Box key={index}>
                            <Stack mt={2.5}>
                              <CollapseInStepper
                                numberVerify={Number(index) + 1}
                                title={"Choose 1 to Verify in this Group"}
                                isCollapse={isCollapse[index]?.isCheck}
                                setIsCollapse={() => toggleCollapse(index)}
                                showArrows={false}
                              />
                            </Stack>
                            {criterionOption?.options.map((criterion, indexOptions) => {
                              const isNameDisabled = isOptionDisabled("FULL_NAME", index);
                              const isCurrentAddressDisabled = isOptionDisabled("CURRENT_ADDRESS", index);
                              const isPreviousAddressDisabled = isOptionDisabled("PREVIOUS_ADDRESS", index);
                              const isDateOfBirthDisabled = isOptionDisabled("DATE_OF_BIRTH", index);
                              const isYearOfBirthDisabled = isOptionDisabled("YEAR_OF_BIRTH", index);
                              const isEmailDisabled = isOptionDisabled("EMAIL_ADDRESS", index);
                              const isPhoneDisabled = isOptionDisabled("PHONE_NUMBER", index);
                              const isTADisabled = isOptionDisabled("TA_ACCOUNT_NUMBER", index);
                              const isCreditorRefDisabled = isOptionDisabled("CREDITOR_REFERENCE", index);
                              const isTransactionIdDisabled = isOptionDisabled("TRANSACTION_ID", index);
                              const isSSNDisabled = isOptionDisabled("SSN_LAST4", index);
                              const isProductDisabled = isOptionDisabled("PRODUCT", index);
                              const isTransactionDateDisabled = isOptionDisabled("TRANSACTION_DATE", index);

                              const currentAddressFiltered = account.debt?.person?.addresses?.filter(
                                item => item?.meta?.is_active
                              );
                              const previousAddressFiltered = account.debt?.person?.addresses?.filter(
                                item => !item?.meta?.is_active
                              );
                              return (
                                <Stack key={`${indexOptions}${criterion}`} direction={"column"} ml={0.1}>
                                  {criterion === "FULL_NAME" &&
                                    !_.isNil(account.debt?.person?.name?.first_name) &&
                                    !isCollapse[index]?.isCheck && (
                                      <RadioChooserStepper
                                        field="FULL_NAME"
                                        selectedOptions={selectedOptions}
                                        isDisabled={isNameDisabled}
                                        index={index}
                                        toggleDisable={toggleDisable}
                                        valueField={`${account.debt?.person?.name?.first_name} ${account.debt?.person?.name?.last_name}`}
                                        valueFormControl={"name"}
                                        label={"Name"}
                                        handleChange={() => handleChangeSimpleStepper(index)}
                                      />
                                    )}
                                  {criterion === "CURRENT_ADDRESS" &&
                                    !_.isNil(currentAddressFiltered) &&
                                    currentAddressFiltered?.length > 0 &&
                                    !isCollapse[index]?.isCheck && (
                                      <RadioChooserStepperAddress
                                        field={"CURRENT_ADDRESS"}
                                        selectedOptions={selectedOptions}
                                        addressFiltered={currentAddressFiltered}
                                        addressLengthOneValue={`${currentAddressFiltered?.[0]?.street_line1} ${currentAddressFiltered?.[0]?.city},${currentAddressFiltered?.[0]?.state} ${currentAddressFiltered?.[0]?.zipcode} ${currentAddressFiltered?.[0]?.country_code}`}
                                        addressType={"Current Address"}
                                        index={index}
                                        handleChangeCurrentAddress={e => handleChangeCurrentAddress(e, index)}
                                        isCurrentAddressDisabled={isCurrentAddressDisabled}
                                        isDisabled={
                                          isCurrentAddressDisabled || currentAddressFiltered?.length === 0
                                        }
                                        toggleDisable={toggleDisable}
                                        valueFormControl={"currentAddress"}
                                        revertLabel={() => handleChangeSimpleStepper(index)}
                                        indexToDelete={indexToDelete}
                                      />
                                    )}
                                  {criterion === "PREVIOUS_ADDRESS" &&
                                    !_.isNil(previousAddressFiltered) &&
                                    previousAddressFiltered?.length > 0 &&
                                    !isCollapse[index]?.isCheck && (
                                      <RadioChooserStepperAddress
                                        field={"PREVIOUS_ADDRESS"}
                                        selectedOptions={selectedOptions}
                                        addressFiltered={previousAddressFiltered}
                                        addressLengthOneValue={`${previousAddressFiltered[0]?.street_line1} ${previousAddressFiltered[0]?.city},${previousAddressFiltered[0]?.state} ${previousAddressFiltered[0]?.zipcode} ${previousAddressFiltered[0]?.country_code}`}
                                        addressType={"Previous Address"}
                                        index={index}
                                        handleChangeCurrentAddress={e =>
                                          handleChangePreviousAddress(e, index)
                                        }
                                        isCurrentAddressDisabled={isPreviousAddressDisabled}
                                        isDisabled={
                                          isPreviousAddressDisabled || previousAddressFiltered?.length === 0
                                        }
                                        toggleDisable={toggleDisable}
                                        valueFormControl={"previousAddress"}
                                        revertLabel={() => handleChangeSimpleStepper(index)}
                                        indexToDelete={indexToDelete}
                                      />
                                    )}

                                  {criterion === "DATE_OF_BIRTH" &&
                                    !_.isNil(account.debt?.person?.date_of_birth?.month) &&
                                    !isCollapse[index]?.isCheck && (
                                      <RadioChooserStepper
                                        field="DATE_OF_BIRTH"
                                        selectedOptions={selectedOptions}
                                        isDisabled={isDateOfBirthDisabled}
                                        index={index}
                                        toggleDisable={toggleDisable}
                                        valueField={`${account.debt?.person?.date_of_birth?.month}/${account.debt?.person?.date_of_birth?.day}/${account.debt?.person?.date_of_birth?.year}`}
                                        valueFormControl={"dateBirth"}
                                        label={"Date of Birth"}
                                        handleChange={() => handleChangeSimpleStepper(index)}
                                      />
                                    )}

                                  {criterion === "YEAR_OF_BIRTH" &&
                                    !_.isNil(account.debt?.person?.date_of_birth?.year) &&
                                    !isCollapse[index]?.isCheck && (
                                      <RadioChooserStepper
                                        field="YEAR_OF_BIRTH"
                                        selectedOptions={selectedOptions}
                                        isDisabled={isYearOfBirthDisabled}
                                        index={index}
                                        toggleDisable={toggleDisable}
                                        valueField={`${account.debt?.person?.date_of_birth?.year}`}
                                        valueFormControl={"yearBirth"}
                                        label={"Year Of Birth"}
                                        handleChange={() => handleChangeSimpleStepper(index)}
                                      />
                                    )}

                                  {criterion === "EMAIL_ADDRESS" &&
                                    !_.isNil(account?.debt?.person?.emails.length) &&
                                    account?.debt?.person?.emails?.length !== 0 &&
                                    !isCollapse[index]?.isCheck && (
                                      <RadioChooserEmailPhone
                                        dataArray={account?.debt?.person?.emails}
                                        field="EMAIL_ADDRESS"
                                        selectValue={email}
                                        handleChange={e => handleChangeEmail(e, index)}
                                        index={index}
                                        isDisabled={isEmailDisabled}
                                        selectedOptions={selectedOptions}
                                        toggleDisable={toggleDisable}
                                        type={"Email"}
                                        valueFormControl={"emailAddress"}
                                        valueLengthOne={`${account.debt?.person?.emails[0]?.email}`}
                                        isPhone={false}
                                        revertLabel={() => handleChangeSimpleStepper(index)}
                                        indexToDelete={indexToDelete}
                                      />
                                    )}

                                  {criterion === "PHONE_NUMBER" &&
                                    !_.isNil(account?.debt?.person?.phones.length) &&
                                    account?.debt?.person?.phones?.length !== 0 &&
                                    !isCollapse[index]?.isCheck && (
                                      <RadioChooserEmailPhone
                                        dataArray={phonesFormatted}
                                        field="PHONE_NUMBER"
                                        selectValue={phone}
                                        handleChange={e => handleChangePhone(e, index)}
                                        index={index}
                                        isDisabled={isPhoneDisabled}
                                        selectedOptions={selectedOptions}
                                        toggleDisable={toggleDisable}
                                        type={"Phone"}
                                        valueFormControl={"phoneNumber"}
                                        valueLengthOne={`${account.debt?.person?.phones[0]?.phone_number}`}
                                        isPhone={true}
                                        revertLabel={() => handleChangeSimpleStepper(index)}
                                        indexToDelete={indexToDelete}
                                      />
                                    )}

                                  {criterion === "TA_ACCOUNT_NUMBER" &&
                                    !_.isNil(account.debt?.account_number) &&
                                    !isCollapse[index]?.isCheck && (
                                      <RadioChooserStepper
                                        field="TA_ACCOUNT_NUMBER"
                                        selectedOptions={selectedOptions}
                                        isDisabled={isTADisabled}
                                        index={index}
                                        toggleDisable={toggleDisable}
                                        valueField={`${account.debt?.account_number}`}
                                        valueFormControl={"taNumber"}
                                        label={"TA Number"}
                                        handleChange={() => handleChangeSimpleStepper(index)}
                                      />
                                    )}

                                  {criterion === "CREDITOR_REFERENCE" &&
                                    !_.isNil(getCreditorReference()) &&
                                    !isCollapse[index]?.isCheck && (
                                      <RadioChooserStepper
                                        field="CREDITOR_REFERENCE"
                                        selectedOptions={selectedOptions}
                                        isDisabled={isCreditorRefDisabled}
                                        index={index}
                                        toggleDisable={toggleDisable}
                                        valueField={getCreditorReference() ?? ""}
                                        valueFormControl={"creditorReference"}
                                        label={"Creditor Reference"}
                                        handleChange={() => handleChangeSimpleStepper(index)}
                                      />
                                    )}

                                  {criterion === "TRANSACTION_ID" &&
                                    !_.isNil(account.debt?.transaction_id) &&
                                    !isCollapse[index]?.isCheck && (
                                      <RadioChooserStepper
                                        field="TRANSACTION_ID"
                                        selectedOptions={selectedOptions}
                                        isDisabled={isTransactionIdDisabled}
                                        index={index}
                                        toggleDisable={toggleDisable}
                                        valueField={`${account.debt?.transaction_id}`}
                                        valueFormControl={"transactionId"}
                                        label={"Transaction Id"}
                                        handleChange={() => handleChangeSimpleStepper(index)}
                                      />
                                    )}

                                  {criterion === "SSN_LAST4" &&
                                    !_.isNil(ssn_last4) &&
                                    !isCollapse[index]?.isCheck && (
                                      <RadioChooserStepper
                                        field="SSN_LAST4"
                                        selectedOptions={selectedOptions}
                                        isDisabled={isSSNDisabled || ssn_last4 === null}
                                        index={index}
                                        toggleDisable={toggleDisable}
                                        valueField={ssn_last4}
                                        valueFormControl={"ssnLast4"}
                                        label={"SSN LAST4"}
                                        handleChange={() => handleChangeSimpleStepper(index)}
                                      />
                                    )}
                                  {criterion === "PRODUCT" &&
                                    !_.isNil(account.debt?.product) &&
                                    !isCollapse[index]?.isCheck && (
                                      <RadioChooserStepper
                                        field="PRODUCT"
                                        selectedOptions={selectedOptions}
                                        isDisabled={isProductDisabled}
                                        index={index}
                                        toggleDisable={toggleDisable}
                                        valueField={`${account.debt?.product}`}
                                        valueFormControl={"product"}
                                        label={"Product"}
                                        handleChange={() => handleChangeSimpleStepper(index)}
                                      />
                                    )}

                                  {criterion === "TRANSACTION_DATE" &&
                                    !_.isNil(account.debt?.transaction_timestamp) &&
                                    !isCollapse[index]?.isCheck && (
                                      <RadioChooserStepper
                                        field="TRANSACTION_DATE"
                                        selectedOptions={selectedOptions}
                                        isDisabled={isTransactionDateDisabled}
                                        index={index}
                                        toggleDisable={toggleDisable}
                                        valueField={`${formatTimestamp(account.debt?.transaction_timestamp)}`}
                                        valueFormControl={"transactionDate"}
                                        label={"Transaction Date"}
                                        handleChange={() => handleChangeSimpleStepper(index)}
                                      />
                                    )}
                                </Stack>
                              );
                            })}
                          </Box>
                        );
                      })}
                    </Stack>
                    <Stack direction={"row"} pt={3} pb={3} justifyContent="flex-end" pr={3}>
                      <Button
                        onClick={() => openModalStartOver(true)}
                        variant="text"
                        sx={{ textTransform: "none", color: theme.palette.primary.dark }}>
                        Start Over
                      </Button>
                      <Stack ml={2}>
                        <Button
                          onClick={() => toggleUnableVerify()}
                          variant="outlined"
                          sx={{ textTransform: "none", color: theme.palette.primary.dark }}>
                          UNABLE TO VERIFY
                        </Button>
                      </Stack>
                      <Stack ml={2}>
                        <Button
                          sx={{ backgroundColor: theme.palette.primary.dark }}
                          variant="contained"
                          disabled={isEnabledButtonNext()}
                          onClick={() => setEnableStepMiniMiranda(true)}>
                          NEXT
                        </Button>
                      </Stack>
                    </Stack>
                  </Grid>
                </>
              )}

              {isEnabledStepMiniMiranda && (
                <Grid
                  xs={9}
                  item
                  sx={{
                    backgroundColor: theme.palette.common.white
                  }}>
                  <Stack
                    direction={"row"}
                    justifyContent="space-between"
                    mt={1}
                    pb={2.625}
                    mr={2.375}
                    sx={{ borderBottom: `1px solid ${theme.palette.grey[200]}` }}>
                    <Stack direction={"row"}>
                      <OverflowTooltip title={"Back to Authentication"}>
                        <ArrowBackIcon
                          sx={{ color: theme.palette.primary.main, fontSize: "31px", cursor: "pointer" }}
                          onClick={() => setEnableStepMiniMiranda(false)}
                        />
                      </OverflowTooltip>
                      <Typography ml={1.75} sx={{ top: "4px", position: "relative" }} variant="h3">
                        Mini Miranda
                      </Typography>
                    </Stack>
                    <Stack direction={"row"}>
                      <Button
                        variant="text"
                        sx={{ textTransform: "none", color: theme.palette.primary.dark }}
                        onClick={() => openModalStartOver(true)}>
                        Start Over
                      </Button>
                      <Stack ml={2}>
                        <Button
                          sx={{ backgroundColor: theme.palette.primary.dark }}
                          variant="contained"
                          onClick={() => {
                            const result = submitCallAuthentication();
                            history.push(`/app/accounts/${account.id}`);
                          }}>
                          LOG AND COMPLETE
                        </Button>
                      </Stack>
                    </Stack>
                  </Stack>
                  <Grid container justifyContent={"center"}>
                    <Grid item>
                      <Stack position={"relative"} top={"100px"}>
                        <svg
                          width="60"
                          height="60"
                          viewBox="0 0 60 60"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M52.5 30.55C52.5 16.825 41.85 7.5 30 7.5C18.275 7.5 7.5 16.625 7.5 30.7C6 31.55 5 33.15 5 35V40C5 42.75 7.25 45 10 45H12.5V29.75C12.5 20.075 20.325 12.25 30 12.25C39.675 12.25 47.5 20.075 47.5 29.75V47.5H27.5V52.5H47.5C50.25 52.5 52.5 50.25 52.5 47.5V44.45C53.975 43.675 55 42.15 55 40.35V34.6C55 32.85 53.975 31.325 52.5 30.55Z"
                            fill="black"
                          />
                          <path
                            d="M22.5 35C23.8807 35 25 33.8807 25 32.5C25 31.1193 23.8807 30 22.5 30C21.1193 30 20 31.1193 20 32.5C20 33.8807 21.1193 35 22.5 35Z"
                            fill="black"
                          />
                          <path
                            d="M37.5 35C38.8807 35 40 33.8807 40 32.5C40 31.1193 38.8807 30 37.5 30C36.1193 30 35 31.1193 35 32.5C35 33.8807 36.1193 35 37.5 35Z"
                            fill="black"
                          />
                          <path
                            d="M44.9999 27.575C43.7999 20.45 37.5999 15 30.1249 15C22.5499 15 14.3999 21.275 15.0499 31.125C21.2249 28.6 25.8749 23.1 27.1999 16.4C30.4749 22.975 37.1999 27.5 44.9999 27.575Z"
                            fill="black"
                          />
                        </svg>
                      </Stack>
                    </Grid>
                    <Grid item>
                      <Stack position={"relative"} top={"130px"}>
                        <svg
                          width="29"
                          height="24"
                          viewBox="0 0 29 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M13.0946 10.8167C13.0946 19.7474 0 23.5749 0 23.5749C0 23.5749 21.545 23.6703 27.3796 18.4716C34.7518 11.9027 13.0946 0.397461 13.0946 0.397461V10.8167Z"
                            fill="#E9E9EB"
                          />
                        </svg>
                      </Stack>
                    </Grid>
                    <Grid item>
                      <Stack direction={"row"} pb={20}>
                        <Stack
                          direction={"row"}
                          px={5}
                          py={3}
                          sx={{
                            width: "608px",
                            backgroundColor: theme.palette.grey[600],
                            borderRadius: "52px",
                            fontWeight: 600,
                            position: "relative",
                            top: "20px"
                          }}>
                          <Typography variant="h2">
                            "TrueAccord is a debt collection agency and this is an attempt to collect debt.
                            Any information obtained will be used for that purpose"
                          </Typography>
                        </Stack>
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              <Grid xs={3} item paddingTop="0px !important">
                <Stack sx={{ backgroundColor: theme.palette.common.white }} p={2}>
                  <Stack mb={1.5}>
                    <Typography variant="h4">
                      {account.debt?.person?.name?.first_name} {account.debt?.person?.name?.last_name}
                    </Typography>
                  </Stack>
                  {account?.debt?.person?.addresses && account?.debt?.person?.addresses?.length > 0 && (
                    <Stack mb={1.5}>
                      <Typography variant="subtitle1">
                        {account?.debt?.person?.addresses[0]?.city},{" "}
                        {account?.debt?.person?.addresses[0]?.state}{" "}
                      </Typography>
                    </Stack>
                  )}
                  {isEnabledStepMiniMiranda && (
                    <>
                      <Stack mb={1.5}>
                        <Typography variant="subtitle1">{account.debt?.creditor?.company_name}</Typography>
                      </Stack>
                      <Stack mb={1.5}>
                        <MonetaryValue
                          variant={"subtitle1"}
                          monetaryAmount={{
                            amount: account.balance?.amount,
                            currency: account.balance?.currency
                          }}
                        />
                      </Stack>
                    </>
                  )}
                  <Stack direction="row" mb={0.5}>
                    <Box
                      sx={{
                        color: theme.palette.common.white,
                        backgroundColor: theme.palette.grey[700],
                        display: "inline-block",
                        width: "max-content",
                        px: 2,
                        py: 1,
                        fontSize: theme.typography.h4,
                        borderRadius: "4px"
                      }}
                      variant="rounded">
                      {account.debt?.status}
                    </Box>
                  </Stack>
                </Stack>
                <Stack sx={{ backgroundColor: theme.palette.common.white }} p={2} mt={1}>
                  <Typography mb={0.5} variant="h6">
                    Agent Help
                  </Typography>
                  {!isEnabledStepMiniMiranda ? (
                    <Typography variant="body1">
                      To authenticate a call, we will ask the caller to confirm specific pieces of
                      information. This process is to ensure that we do not disclose any private information
                      to the wrong party. The information required to authenticate could be different based on
                      each client's requirements.
                      <br />
                      <br />
                      The authentication workflow in Customer Engagement will tell you what is required for
                      authentication during the call.
                      <br />
                      <br />
                      This information is provided by the Client as necessary to verify before discussing the
                      account details. The information may be different for different Clients.
                      <br />
                      <br />
                      Once the call is fully authenticated, make sure all required disclosures are read to the
                      customer.
                      <br />
                      <br />
                      For more information on Authentication, click{" "}
                      <a
                        style={{ textDecoration: "none", color: theme.palette.primary.main }}
                        href="https://trueaccord.atlassian.net/wiki/spaces/OP/pages/425226/Authenticating+a+Call">
                        here
                      </a>
                      .
                    </Typography>
                  ) : (
                    <Typography variant="body1">
                      Once all necessary information has been verified, read the Mini-Miranda to the Customer.
                      Click the [Log and Complete] button to log this action and continue.
                    </Typography>
                  )}
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        )}
      </>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        open={isOpenToast}
        autoHideDuration={6000}
        sx={{
          ".MuiAlert-icon": {
            display: "none"
          }
        }}
        onClose={() => setOpenToast(false)}>
        <Alert
          onClose={() => setOpenToast(false)}
          sx={{
            width: "100%",
            color: theme.palette.common.white,
            backgroundColor: theme.palette.success.dark,
            position: "relative",
            left: "-150px",
            top: "-60px"
          }}>
          All verifications are successful. Please click Next to proceed.{" "}
          <span style={{ textDecoration: "underline" }}>NEXT</span>
        </Alert>
      </Snackbar>

      <Modal
        style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        open={isOpenModalStartOver}
        onClose={() => openModalStartOver(false)}>
        <Stack
          p={3}
          sx={{
            backgroundColor: theme.palette.common.white,
            width: "590px",
            height: "302"
          }}>
          <Stack mb={2.5} direction="row" justifyContent={"space-between"}>
            <Typography variant="h4">Start Over</Typography>
            <Stack onClick={() => openModalStartOver(false)}>
              <CloseSharpIcon />
            </Stack>
          </Stack>
          <Stack mb={3}>
            <Typography variant="body1">
              By leaving this page you will lose any previously input information.
            </Typography>
          </Stack>
          <Stack mb={3}>
            <Typography variant="body1">Click [Stay] to stay on the current page</Typography>
          </Stack>
          <Stack>
            <Typography variant="body1">Click [Start Over] to return to the first screen</Typography>
          </Stack>
          <Stack direction={"row"} justifyContent="flex-end" pt={1}>
            <Button
              sx={{ color: theme.palette.primary.dark, borderColor: theme.palette.primary.dark }}
              onClick={() => openModalStartOver(false)}
              variant="outlined">
              STAY
            </Button>
            <Button
              sx={{ marginLeft: "15px", backgroundColor: theme.palette.primary.dark }}
              variant="contained"
              onClick={() => {
                if (isEnabledStepMiniMiranda) {
                  setEnableStepMiniMiranda(false);
                }
                openModalStartOver(false);
                startOver();
              }}>
              START OVER
            </Button>
          </Stack>
        </Stack>
      </Modal>

      <Modal
        style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        open={isOpenModalUnableVerify}
        onClose={() => setOpenModalUnableVerify(false)}>
        <Stack direction={"row"}>
          <Stack
            sx={{
              backgroundColor: theme.palette.common.white,
              width: "590px",
              height: "360"
            }}>
            <Stack mb={3.375} direction="row" justifyContent={"space-between"} mt={2} px={2}>
              <Stack>
                <Typography variant="h4">Verification Unsuccessful</Typography>
              </Stack>
              <Stack onClick={() => setOpenModalUnableVerify(false)}>
                <CloseSharpIcon />
              </Stack>
            </Stack>
            <Stack direction={"row"} pl={2}>
              <Stack position={"relative"} top={"-12px"}>
                <svg
                  width="60"
                  height="60"
                  viewBox="0 0 60 60"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M52.5 30.55C52.5 16.825 41.85 7.5 30 7.5C18.275 7.5 7.5 16.625 7.5 30.7C6 31.55 5 33.15 5 35V40C5 42.75 7.25 45 10 45H12.5V29.75C12.5 20.075 20.325 12.25 30 12.25C39.675 12.25 47.5 20.075 47.5 29.75V47.5H27.5V52.5H47.5C50.25 52.5 52.5 50.25 52.5 47.5V44.45C53.975 43.675 55 42.15 55 40.35V34.6C55 32.85 53.975 31.325 52.5 30.55Z"
                    fill="black"
                  />
                  <path
                    d="M22.5 35C23.8807 35 25 33.8807 25 32.5C25 31.1193 23.8807 30 22.5 30C21.1193 30 20 31.1193 20 32.5C20 33.8807 21.1193 35 22.5 35Z"
                    fill="black"
                  />
                  <path
                    d="M37.5 35C38.8807 35 40 33.8807 40 32.5C40 31.1193 38.8807 30 37.5 30C36.1193 30 35 31.1193 35 32.5C35 33.8807 36.1193 35 37.5 35Z"
                    fill="black"
                  />
                  <path
                    d="M44.9999 27.575C43.7999 20.45 37.5999 15 30.1249 15C22.5499 15 14.3999 21.275 15.0499 31.125C21.2249 28.6 25.8749 23.1 27.1999 16.4C30.4749 22.975 37.1999 27.5 44.9999 27.575Z"
                    fill="black"
                  />
                </svg>
              </Stack>
              <Stack ml={3.25}>
                <Typography variant="h4">
                  "I am unable to match records with <br />
                  the information you have provided me"
                </Typography>
              </Stack>
            </Stack>

            <Stack ml={3.25}>
              <ul>
                <li>
                  <Typography>
                    Encourage the customer to gather more information to locate the <br />
                    account. Select [Cancel] if the customer locates the information.
                  </Typography>
                </li>
              </ul>
              OR
              <ul>
                <li>
                  <Typography>
                    Ask customer to submit an email to support@trueaccord.com for <br />
                    further assistance via written communication
                  </Typography>
                </li>
              </ul>
            </Stack>

            <Stack direction={"row"} justifyContent="flex-end" pt={1} pb={2.875} pr={4.125}>
              <Button
                sx={{ color: theme.palette.primary.dark, borderColor: theme.palette.primary.dark }}
                onClick={() => handleClickCancelUnableVerify()}
                variant="outlined">
                CANCEL
              </Button>
              <Button
                sx={{ marginLeft: "26px", backgroundColor: theme.palette.primary.dark }}
                onClick={() => handleConfirmModalMiniMiranda()}
                variant="contained">
                CONFIRM
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Modal>
    </>
  );
}
