import type { StrictTypedTypePolicies } from "~/gql/apollo-helpers";
import Debug from "debug";

const DEBUG = Debug("graphql");
export const CAMUNDA_USER_POLICY: StrictTypedTypePolicies["camunda_User"] = {
  fields: {
    regionRestriction: {
      read(_, {
        args
      }) {
        
        if (isRegionRestrictionArgs(args)) {
          DEBUG("camunda_User.regionRestriction.read.args", args);
          return args.setTo;
        } else {
          return true;
        }
      }
    }
  }
};

interface RegionRestrictionArgs {
  setTo: boolean;
}

function isRegionRestrictionArgs(args: any): args is RegionRestrictionArgs {
  return args && typeof args.setTo === "boolean";
}
